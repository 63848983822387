<template>
  <div class="vehicleequipmentWarehousing">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="车载设备名称">
            <el-input v-model="form.deviceName" placeholder="车载设备名称" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="deviceName" label="车载设备名称" align="center"></el-table-column>
          <el-table-column prop="deviceNumber" label="本次车载设备入库数量" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">车载设备详情</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="selectFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车载设备名称" prop="deviceName">
              <el-input v-model="selectForm.deviceName" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="本次车载设备入库数量" prop="deviceNumber">
              <el-input
                v-model="selectForm.deviceNumber"
                autocomplete="off"
                :disabled="isDisabled"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="selectForm.remark" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加转运收费标准</span>
        <el-form :model="addForm" inline ref="addForm" :rules="addFormRules" class="addForm">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车载设备名称" prop="deviceName">
              <el-input v-model="addForm.deviceName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="本次车载设备入库数量" prop="deviceNumber">
              <el-input
                v-model="addForm.deviceNumber"
                autocomplete="off"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'vehicleequipmentWarehousing',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { deviceName: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        deviceName: [
          { required: true, message: '请输入车载设备名称', trigger: 'blur' }
        ],
        deviceNumber: [
          { required: true, message: '请输入本次车载设备入库数量', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        deviceName: [
          { required: true, message: '请输入车载设备名称', trigger: 'blur' }
        ],
        deviceNumber: [
          { required: true, message: '请输入本次车载设备入库数量', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '正常', status: 1 }, { label: '停用', status: 2 }]
    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有车载设备入库记录8908
    async getAllCar () {
      const { data: res } = await this.$http.post('/vehicleServer/tInfoDeviceIn/selecteTInfoDeviceInService', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
        // "status": 1
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取车载设备信息失败')
      this.tabData = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.deviceName === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.PageNo = 1
        const { data: res } = await this.$http.post('/vehicleServer/tInfoDeviceIn/selecteTInfoDeviceInService', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          deviceName: this.form.deviceName
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data
        this.total = +res.pageBean.pageDataCount
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.deviceName}`, '删除车载设备信息', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/vehicleServer/tInfoDeviceIn/deleteTInfoDeviceInService', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // /     / 进行编辑操作
          const { data: res } = await this.$http.post('/vehicleServer/tInfoDeviceIn/updateTInfoDeviceInService', JSON.parse(JSON.stringify(this.selectForm)))

          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        this.addForm.transportTypeId = +this.addForm.transportTypeId
        this.addForm.companyId = +this.addForm.companyId
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/vehicleServer/tInfoDeviceIn/insertTInfoDeviceInService', JSON.parse(JSON.stringify(this.addForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.vehicleequipmentWarehousing {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
